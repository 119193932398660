import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Employee, Page } from "@/types";

type EmployeeState = {
  employeePage: Page<Employee>;
  serviceRoomPage: Page<any>;
  roomAvailability: any[];
};

const employee: Module<EmployeeState, unknown> = {
  namespaced: true,
  state: () => ({
    employeePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    serviceRoomPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    roomAvailability: [],
  }),
  getters: {
    getEmployee: (state) => (employeeId: string) =>
      state.employeePage.docs.find((c) => c._id === employeeId),
    employeePage: (state) => state.employeePage,
    serviceRoomPage: (state) => state.serviceRoomPage,
    roomAvailability: (state) => state.roomAvailability,
  },
  mutations: {
    ADD_EMPLOYEE: (state, _employee) => {
      let idx = -1;
      state.employeePage.docs.map((a, i) => {
        if (a._id === _employee._id) idx = i;
      });
      if (idx === -1) state.employeePage.docs.push(_employee);
      else Vue.set(state.employeePage.docs, idx, _employee);
      state.employeePage.limit = state.employeePage.limit || 10;
      state.employeePage.page = state.employeePage.page || 1;
      state.employeePage.pages = state.employeePage.pages || 1;
      state.employeePage.total = state.employeePage.total || 1;
    },
    SET_EMPLOYEE_PAGE: (state, list) => {
      state.employeePage = list;
    },
    REMOVE_EMPLOYEE: (state, employee) => {
      const index = state.employeePage.docs.findIndex(
        (item) => item._id === employee._id
      );
      state.employeePage.docs.splice(index, 1);
    },
    ADD_SERVICE_ROOM: (state, room) => {
      let idx = -1;
      state.serviceRoomPage.docs.map((a, i) => {
        if (a._id === room._id) idx = i;
      });
      if (idx === -1) state.serviceRoomPage.docs.push(room);
      else Vue.set(state.serviceRoomPage.docs, idx, room);
      state.serviceRoomPage.limit = state.serviceRoomPage.limit || 10;
      state.serviceRoomPage.page = state.serviceRoomPage.page || 1;
      state.serviceRoomPage.pages = state.serviceRoomPage.pages || 1;
      state.serviceRoomPage.total = state.serviceRoomPage.total || 1;
    },
    SET_SERVICE_ROOM_PAGE: (state, list) => {
      state.serviceRoomPage = list;
    },
    REMOVE_SERVICE_ROOM: (state, room) => {
      const index = state.serviceRoomPage.docs.findIndex(
        (item) => item._id === room._id
      );
      state.serviceRoomPage.docs.splice(index, 1);
    },
    SET_ROOM_AVAILABILITY: (state, availability) => {
      state.roomAvailability = availability;
    },
  },
  actions: {
    async fetchEmployee(context, params = "") {
      return await api
        .get(`/v1/employee${params}`)
        .then((response) => {
          context.commit("ADD_EMPLOYEE", response.data.employee);
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchEmployeeList(context, params = "") {
      return await api
        .get(`/v1/employee${params}`)
        .then((response) => {
          context.commit("SET_EMPLOYEE_PAGE", response.data.employeePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createEmployee(context, payload) {
      return await api
        .post(`/v1/employee`, payload, { timeout: 20000 }) // Uploading some images could take time!
        .then((response) => {
          context.commit("ADD_EMPLOYEE", response.data.employee);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Staff created",
            },
            { root: true }
          );
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateEmployee(context, data: { id: string; employee: Employee }) {
      return await api
        .put(`/v1/employee/${data.id}`, data.employee)
        .then((response) => {
          context.commit("ADD_EMPLOYEE", response.data.employee);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Staff updated",
            },
            { root: true }
          );
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportEmployees(context, params = "") {
      return await api
        .get(`/v1/employee/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async getWithAvailability(context, params = "") {
      return await api
        .get(`/v1/employee/get-with-availability${params}`)
        .then((response) => {
          context.commit("SET_EMPLOYEE_PAGE", {
            docs: response.data.employees,
          });
          return response.data.employees;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteEmployee(context, id) {
      return await api
        .delete(`/v1/employee/${id}`, {
          headers: { "vendor-module": "employees.All Employees" },
        })
        .then((response) => {
          context.commit("REMOVE_EMPLOYEE", response.data.employee);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Employee deleted",
            },
            { root: true }
          );
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchServiceRoomList(context, params = "") {
      return await api
        .get(`/v1/serviceRoom${params}`)
        .then((response) => {
          context.commit(
            "SET_SERVICE_ROOM_PAGE",
            response.data.serviceRoomPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createServiceRoom(context, payload) {
      return await api
        .post(`/v1/serviceRoom`, payload)
        .then((response) => {
          context.commit("ADD_SERVICE_ROOM", response.data.serviceRoom);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service Room created",
            },
            { root: true }
          );
          return response.data.serviceRoom;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateServiceRoom(context, data) {
      return await api
        .put(`/v1/serviceRoom/${data.serviceRoomId}`, { ...data })
        .then((response) => {
          context.commit("ADD_SERVICE_ROOM", response.data.serviceRoom);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service Room updated",
            },
            { root: true }
          );
          return response.data.serviceRoom;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteServiceRoom(context, id) {
      return await api
        .delete(`/v1/serviceRoom/${id}`)
        .then((response) => {
          context.commit("REMOVE_SERVICE_ROOM", response.data.serviceRoom);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service Room deleted",
            },
            { root: true }
          );
          return response.data.serviceRoom;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchRoomAvailability(context, serviceRequests = []) {
      try {
        // Convert single request to array format if needed
        const requestPayload = Array.isArray(serviceRequests)
          ? serviceRequests
          : [
              {
                serviceId: serviceRequests.serviceId,
                businessId: serviceRequests.businessId,
                startDateIso: serviceRequests.startDate, // Note: Converting from startDate to startDateIso
                duration: Number(serviceRequests.duration),
              },
            ];

        // Always use POST request with array in body
        const response = await api.post(
          `/v1/serviceRoom/availability`,
          requestPayload
        );

        // Handle response data based on whether it was a single request or multiple
        let availabilityData = response.data.availability;

        // For single request compatibility, extract the first result if it was converted from a single object
        if (
          !Array.isArray(serviceRequests) &&
          Object.keys(availabilityData).length === 1
        ) {
          availabilityData = availabilityData[Object.keys(availabilityData)[0]];
        }

        context.commit("SET_ROOM_AVAILABILITY", availabilityData);
        return availabilityData;
      } catch (error) {
        context.dispatch(
          "setToast",
          {
            title: "Request failed!",
            type: "error",
            text:
              (error as any).response?.data?.error?.message ||
              "Failed to fetch room availability",
          },
          { root: true }
        );

        // Re-throw the error so the caller can handle it if needed
        throw error;
      }
    },
  },
};

export default employee;
